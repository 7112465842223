import React, { useEffect, useState } from "react";
import WebView from "../components/Main/WebView";
import { getPortfolioItems } from "../services/portfolio.service";

export default function Projects() {
  const [portfolioItems, setPortfolioItems] = useState([]);

  useEffect(() => {
    const fetchPortfolioItems = async () => {
      try {
        const items = await getPortfolioItems();
        setPortfolioItems(items);
        console.log("Portfolio items:", items);
      } catch (err) {
        console.log(err);
      }
    };

    fetchPortfolioItems();
  }, []);

  return (
    <main className="container mx-auto pt-12 px-4">
      <h1 className="text-center text-4xl py-12 uppercase text-white font-bold">My Projects</h1>

      <div className="flex lg:flex-row flex-col flex-wrap lg:justify-evenly gap-12 items-center relative z-10 pb-12">
        {portfolioItems.map((item) => (
          <WebView
            key={item.id}
            url={item.url}
            img={item.img}
            title={item.title}
            width="800px"
            height="400px"
            button-text="Turn on TV"
            className="my-custom-class"
          />
        ))}
      </div>
    </main>
  );
}
