
import React from 'react'
import { Link } from 'react-router-dom'
import { cn } from '../../utils/cn'

export default function Button({children, className, href, ...props}) {
  return (
    <div className={cn('rounded-xl hover:scale-95 transition-all duration-300 flex items-center justify-center shadow-md cursor-pointer bg-primary text-white', className, href ? '' : 'py-3 px-5')} {...props}>
      {href ? <Link to={href} className="py-3 px-5 w-full h-full flex items-center justify-center"> {children} </Link> : children}
    </div>
  )
}

 