import React from "react";
import Hero from "../components/Homepage/Hero";

export default function Home() {
  return (
    <main className="flex items-center relative z-10">
      <Hero />
    </main>
  );
}
