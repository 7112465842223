import React from "react";
import { useInView } from "react-intersection-observer";
import { useTypewriter } from "react-simple-typewriter";
import Button from "../ui/Button";
import { FaArrowRight, FaArrowRightLong } from "react-icons/fa6";
import {
  FaFacebook,
  FaGithubSquare,
  FaInstagram,
  FaLaravel,
  FaLinkedin,
  FaNodeJs,
  FaReact,
  FaTelegram,
} from "react-icons/fa";
import { cn } from "../../utils/cn";
import { SiNextdotjs } from "react-icons/si";
import { RiTailwindCssFill } from "react-icons/ri";

const socials = [
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/younes-islam-kaouani-b0951827a/",
    img: <FaLinkedin />,
  },
  {
    name: "GitHub",
    link: "https://github.com/younesKAOUANI",
    img: <FaGithubSquare />,
  },
  {
    name: "Telegram",
    link: "https://twitter.com/MoElsayed98",
    img: <FaTelegram />,
  },
  {
    name: "Instagram",
    link: "https://t.me/nyctophilixic",
    img: <FaInstagram />,
  },
  {
    name: "Facebook",
    link: "https://web.facebook.com/nyctophilixic",
    img: <FaFacebook />,
  },
];
const languages = [
  {
    name: "Next.js",
    img: <SiNextdotjs />,
  },
  {
    name: "React.js",
    img: <FaReact />,
  },
  {
    name: "Tailwind CSS",
    img: <RiTailwindCssFill />,
  },
  {
    name: "Node.js",
    img: <FaNodeJs />,
  },
  {
    name: "Laravel",
    img: <FaLaravel />,
  },
];
export default function Hero() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });

  const [text] = useTypewriter({
    words: ["Software Engineer & Web Developer"],
    loop: 1,
    typeSpeed: 70,
    deleteSpeed: 50,
    delaySpeed: 1000,
  });
  return (
    <section className="container flex flex-col gap-12 mx-auto px-4 lg:pt-24 py-12 text-white">
      <div
        className="flex lg:flex-row flex-col lg:justify-between justify-center lg:text-left text-center gap-12 items-center"
        ref={ref}
      >
        {inView && (
          <>
            <h1 className="text-4xl font-bold">{text}</h1>
          </>
        )}
        <Button
          className={"font-black text-2xl uppercase flex gap-4 items-center"}
          href={"/projects"}
        >
          Projects{" "}
          <span className="ml-3 text-lg p-2 border-2 rounded-full">
            {" "}
            <FaArrowRight />{" "}
          </span>
        </Button>
      </div>
      <div>
        <h2 className="text-center text-2xl mb-6 uppercase mt-12 lg:mt-0">
          Proficiencies
        </h2>
        <div className="flex flex-wrap gap-4 gap-y-8 items-center justify-center lg:justify-evenly border-2 rounded-lg py-6 backdrop-blur-sm backdrop-grayscale">
          {languages.map((languages) => (
            <div key={languages.name}>
              <div className="flex flex-col items-center gap-4 uppercase w-32">
                <span className="text-6xl">{languages.img}</span>
                {languages.name}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="pb-12">
        <h2 className="text-center text-2xl mb-6 mt-12 lg:mt-0 uppercase">
          Socials
        </h2>
        <div className="flex flex-wrap gap-2 items-center justify-center lg:justify-evenly">
          {socials.map((social) => (
            <Button key={social.name} href={social.link}>
              <div className="flex items-center gap-4 uppercase">
                <span className="text-2xl">{social.img}</span>
                {social.name}
              </div>
            </Button>
          ))}
        </div>
      </div>
    </section>
  );
}
