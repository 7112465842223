import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Button from "../ui/Button";
import { Link } from "react-router-dom";
import { Menu, X, Phone, Mail } from "lucide-react";
import { IoMdDownload } from "react-icons/io";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const menuVariants = {
    closed: {
      opacity: 0,
      y: "-100%",
      transition: {
        duration: 0.5,
        ease: "easeInOut"
      }
    },
    open: {
      opacity: 1,
      y: "0%",
      transition: {
        duration: 0.5,
        ease: "easeInOut"
      }
    }
  };

  const itemVariants = {
    closed: { opacity: 0, y: -20 },
    open: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.4
      }
    }
  };

  const contactVariants = {
    closed: { opacity: 0, x: -20 },
    open: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.4,
        delay: 0.3
      }
    }
  };

  return (
    <>
      <header className="sticky py-4 text-white w-full z-30 px-4">
        <div className="container mx-auto">
          <div className="flex justify-between items-center">
            <Link href="/" className="md:text-2xl text-lg font-black uppercase">Kaouani Younes</Link>
            <div className="flex items-center gap-4">
              <Button className="font-black uppercase md:py-2 py-1 flex items-center gap-2">
                Resume
                <span className="text-xl">
                <IoMdDownload />
                </span>
              </Button>
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="ml-6 p-2 hover:bg-white/10 rounded-full transition-colors"
              >
                {isOpen ? <X size={36} /> : <Menu size={36} />}
              </button>
            </div>
          </div>
        </div>
      </header>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={menuVariants}
            className="fixed inset-0 bg-black/95 z-20 flex items-center justify-center"
          >
            <div className="container mx-auto px-4">
              <div className="grid md:grid-cols-2 gap-12 items-center">
                <nav className="flex flex-col gap-8">
                  <motion.div variants={itemVariants}>
                    <NavItem href="/" onClick={() => setIsOpen(false)}>
                      Home
                    </NavItem>
                  </motion.div>
                  {/* <motion.div variants={itemVariants}>
                    <NavItem href={"/about"} onClick={() => setIsOpen(false)}>
                      About
                    </NavItem>
                  </motion.div> */}
                  <motion.div variants={itemVariants}>
                    <NavItem href="/projects" onClick={() => setIsOpen(false)}>
                      Projects
                    </NavItem>
                  </motion.div>
                  {/* <motion.div variants={itemVariants}>
                    <NavItem href="/contact" onClick={() => setIsOpen(false)}>
                      Contact
                    </NavItem>
                  </motion.div> */}
                </nav>

                <motion.div 
                  variants={contactVariants} 
                  className="text-white space-y-6"
                >
                  <h3 className="text-2xl font-black uppercase mb-8">
                    Get in Touch
                  </h3>
                  <a
                    href="tel:+1234567890"
                    className="flex items-center gap-4 text-lg hover:text-primary transition-colors"
                  >
                    <Phone size={20} />
                    +213 556 046 514
                  </a>
                  <a
                    href="mailto:kaouani.younes@proton.me"
                    className="flex items-center gap-4 text-lg hover:text-primary transition-colors"
                  >
                    <Mail size={20} />
                    kaouani.younes@proton.me
                  </a>
                </motion.div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

function NavItem({ href, children, onClick }) {
  return (
    <Link
      to={href}
      onClick={onClick}
      className="text-white text-4xl md:text-5xl hover:text-primary transition-colors duration-200 uppercase font-black h-full w-full"
    >
      {children}
    </Link>
  );
}